@import '@/styles/_shared.scss';

.card {
  display: flex;
  background-color: #faf9f9;
  margin: 18px 0px;
  font-size: 1rem;
  line-height: 150%;
  font-family: $frank-ruhl;
  font-weight: 400;
  border: 2px solid #b7cd96;
  border-radius: 16px;
  font-family: $bebas !important;

  .price_info {
    font-size: 20px;
    color: #054074;
    text-transform: uppercase;
    .price {
      font-weight: bold;
    }
    .strikethrough-price {
      text-decoration: line-through;
    }
  }
  .flex_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .card__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    border-radius: 12px;
    // border: 1px solid $fawn;
    padding: 12px 12px 26px 12px;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 736px) {
      padding: 25px 25px 31px 25px;
    }

    :global(a.btn) {
      margin-top: 32px;
    }
  }

  .best-seller {
    text-align: center;
    background-color: #b7cd96;
    color: #1a507f;
    border-radius: 48px;
    min-height: 25px;
    min-width: 156px;
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
    text-transform: uppercase;
    font-size: 16px;
    padding: 8px 20px;
    font-family: $bebas;
    font-weight: 500;

    @media screen and (min-width: 736px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .metafield {
    margin-top: 17.41px;
    line-height: 140%;
    font-weight: 500;
    height: 70px;
    text-overflow: ellipsis;
  }

  .media {
    margin-bottom: 25px;
    display: block;
  }

  // card__content
  &__content {
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: auto;

    .title {
      margin-top: 20px;
      margin-bottom: 12px;
      color: #054074;
    }
  }
  .bottom_block {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    a {
      width: 50%;
      font-size: 20px;
      font-family: $bebas;
    }
  }
}

.media {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 20vh;
  border-radius: 12px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
