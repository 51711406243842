@import '@/styles/_shared.scss';

.featured_block {
  height: 600px;
  position: relative;
  background-color: #003c71;
  font-family: $bebas !important;
  .divider {
    margin: 30px 10% -20px;
    height: 0px;
    border: 1px solid #285a87;
  }
  .product-slider {
    margin-right: 10%;
    margin-left: 10%;
  }
  .product-slide {
    width: calc(50% - 18px);
    @include breakpoint-down('sm') {
      width: 100%;
    }
  }
  &_body {
    position: absolute;
    top: -130px;
  }
  &_header-text {
    width: 100%;
    color: #b7cd96;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    font-family: $bebas;
    font-weight: 500;
  }
  &__image {
    width: 80%;
    img {
      border-radius: 8px;
      border: 2px solid #93a880 !important;
    }
  }
  &__footer {
    margin: 4px 20% 40px;
    padding: 10px 30px;
    height: 200px;
    display: flex;
    align-items: center;
    gap: 16px;
    &--text {
      // display: flex;
      h1 {
        font-size: 24px;
        color: $alabaster;
      }
      h2 {
        font-size: 24px;
        color: #5a80a2;
      }
    }
  }
  &__image1 {
    height: 100px;
    width: 150px;
  }
  // dynamic color modifiers
  &.bg--light {
    background-color: $alabaster;
  }

  &.bg--dark {
    background-color: $sitkablue;
  }

  &.bg--green {
    background-color: #b7cd96;
  }

  &.bg--yellow {
    background-color: $fawn50-2;
  }

  &.text--light {
    color: $alabaster;

    .secondary-btn {
      color: $alabaster;
    }

    .disclaimer a {
      color: $alabaster;
    }
  }

  &.text--dark {
    color: $sitkablue;

    .secondary-btn {
      color: $sitkablue;
    }

    .disclaimer a {
      color: $sitkablue;
    }
  }

  &.text--green {
    color: #b7cd96;

    .secondary-btn {
      color: #b7cd96;
    }

    .disclaimer a {
      color: #b7cd96;
    }
  }

  .disclaimer {
    display: block;

    p {
      font-size: 14px;
    }
  }

  // featured_block__row
  &__row {
    display: flex;
    position: relative;

    &.justify-center {
      justify-content: center;
    }
  }

  // featured_block__text
  &__text {
    height: 100%;
    flex: 1;

    &--title {
      position: absolute;
      top: 100px;
      text-align: center;
    }

    // featured_block__text--inner
    &--inner {
      position: relative;

      h1 {
        margin-bottom: 12px;

        @include breakpoint-up('sm') {
          margin-bottom: 18px;
        }
      }

      .value-props {
        list-style: none;
        margin-bottom: 76px;

        @include breakpoint-up('sm') {
          margin-bottom: 44px;
        }

        li {
          font-weight: 500;

          span {
            display: inline-block;
            margin-right: 12.35px;

            @include breakpoint-up('sm') {
              margin-right: 24.71px;
            }
          }

          svg {
            width: 17px;

            @include breakpoint-up('sm') {
              width: 27px;
            }
          }
        }

        & li:not(:first-child) {
          margin-top: 28px;
        }
      }
    }
  }

  .disclaimer {
    a {
      font-size: 14px;
    }
  }

  // featured_block__wrap
  &__wrap {
    position: relative;

    &.image--half {
      @include breakpoint-up('sm') {
        width: 50%;
      }
    }

    &.image--two-thirds {
      @include breakpoint-up('sm') {
        width: 58.8%;
      }
    }

    // featured_block__wrap--dsktp
    &--dsktp {
      display: none;
    }

    // featured_block__wrap--mbl
    &--mbl {
      @include breakpoint-up('sm') {
        display: none;
      }
    }

    img {
      object-fit: cover;
    }
  }

  // Padded featured_block Option
  &.image--padded {
    .featured_block__text {
      &--inner {
        padding: 24px 28px 60px 28px;
        text-align: left;

        @include breakpoint-up('sm') {
          padding: 0 10.2% 0 0;
        }

        .subheader {
          margin-bottom: 18px;

          @include breakpoint-up('sm') {
            margin-bottom: 36px;
          }
        }
      }
    }

    .featured_block__wrap {
      &--mbl {
        height: 242px;
      }
    }

    .featured_block__wrap {
      &--dsktp {
        @include breakpoint-up('sm') {
          display: block;
          height: 792px;
          border-radius: 12px;

          img {
            border-radius: 12px;
          }
        }
      }
    }

    .featured_block__row {
      flex-direction: column-reverse;

      @include breakpoint-up('sm') {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        padding: 84px 4.27% 84px 6.3%;
      }
    }

    .btn-wrap {
      display: flex;
      align-items: center;

      .secondary-btn {
        background: none;
      }
    }
  }

  // Cover featured_block Option
  &.image--cover {
    .featured_block__text {
      // featured_block__text--inner
      &--inner {
        padding: 54px 28px 111px 28px;
        text-align: center;

        @include breakpoint-up('sm') {
          padding: 0 12.6% 0 16.77%;
          text-align: left;
        }

        .btn-wrap {
          position: absolute;
          transform: translateX(-50%);
          bottom: -20px;
          left: 50%;
          z-index: 1;

          @include breakpoint-up('sm') {
            position: static;
            transform: initial;
            display: flex;
            align-items: center;
          }

          .primary-btn {
            width: 319px;
          }
        }

        h2 {
          margin-bottom: 18px;

          @include breakpoint-up('sm') {
            margin-bottom: 36px;
          }
        }
      }
    }

    .featured_block__wrap {
      // featured_block__wrap--mbl
      &--mbl {
        height: 300px;
      }

      // featured_block__wrap--dsktp
      &--dsktp {
        @include breakpoint-up('sm') {
          display: block;
          height: 963px;
        }
      }
    }

    .featured_block__row {
      flex-direction: column;

      @include breakpoint-up('sm') {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
      }
    }

    .value-props {
      display: none;

      @include breakpoint-up('sm') {
        display: block;
      }
    }
  }

  // .divider {
  //   width: 0px;
  //   height: 30%;
  //   margin-right: 32px;
  //   margin-left: 32px;
  //   border: 1px solid #dfdfdf77;
  // }

  .btn-wrap {
    flex-wrap: wrap;
    @include breakpoint-up('sm') {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }

    .primary-btn {
      max-width: 319px;
      margin-bottom: 12px;

      @include breakpoint-up('sm') {
        max-width: 250px;
        margin-bottom: 0;
        margin-right: 72px;
      }
    }

    .footer-btn {
      max-width: 319px;
      min-width: 150px !important;
      margin-bottom: 12px;

      @include breakpoint-up('sm') {
        max-width: 250px;
        margin-bottom: 0;
        // margin-right: 72px;
      }
    }

    .secondary-btn {
      @include breakpoint-up('sm') {
        text-align: left;
      }
    }

    h2 {
      margin-left: 36px;
    }
  }
}

.product-slider {
  max-width: 100vw;
  margin-bottom: 25px;

  @include breakpoint-up('sm') {
    margin-bottom: 30px;
  }

  .image {
    border-radius: 12px;
  }
}

.product-image {
  margin-bottom: 10px;
  border-radius: 6px;

  @include breakpoint-up('sm') {
    margin-bottom: 20px;
  }
}
